var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index-page",
    class: _vm.$mq
  }, [_c('img', {
    staticClass: "index-page__background",
    class: _vm.$mq,
    attrs: {
      "src": require(`@/assets/images/index_bg.svg`)
    }
  }), _c('div', {
    staticClass: "lights"
  }, [_c('svg', {
    attrs: {
      "width": "1440",
      "height": "1105",
      "viewBox": "0 0 1440 1105",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "filter": "url(#filter0_f_1103:7627)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1358.92 -62.8208C1358.92 -62.8208 1406.32 35.8042 1394.11 71.0213C1381.91 106.238 1210.44 151.317 1179.14 140.469C1147.84 129.621 1144.67 42.9283 1156.88 7.71121C1169.08 -27.5059 1358.92 -62.8208 1358.92 -62.8208Z",
      "fill": "#A09EFF"
    }
  })]), _c('g', {
    attrs: {
      "opacity": "0.5",
      "filter": "url(#filter1_f_1103:7627)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M448.83 540.308C448.83 540.308 493.284 632.929 481.818 666.011C470.352 699.093 113.57 786.004 84.1992 775.825C54.8287 765.645 51.8787 684.219 63.3447 651.137C74.8106 618.055 448.83 540.308 448.83 540.308Z",
      "fill": "#FF78A9",
      "fill-opacity": "0.85"
    }
  })]), _c('g', {
    attrs: {
      "filter": "url(#filter2_f_1103:7627)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1149.92 589.636C1149.92 589.636 1189.28 671.54 1179.15 700.787C1169.01 730.034 1026.61 767.47 1000.62 758.461C974.629 749.452 971.994 677.457 982.131 648.21C992.268 618.964 1149.92 589.636 1149.92 589.636Z",
      "fill": "#F2EEC9"
    }
  })]), _c('defs', [_c('filter', {
    attrs: {
      "id": "filter0_f_1103:7627",
      "x": "822.259",
      "y": "-390.821",
      "width": "901.863",
      "height": "860.933",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1103:7627"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter1_f_1103:7627",
      "x": "-270.882",
      "y": "212.308",
      "width": "1082.59",
      "height": "892.34",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1103:7627"
    }
  })], 1), _c('filter', {
    attrs: {
      "id": "filter2_f_1103:7627",
      "x": "648.634",
      "y": "261.636",
      "width": "860.182",
      "height": "826.191",
      "filterUnits": "userSpaceOnUse",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c('feFlood', {
    attrs: {
      "flood-opacity": "0",
      "result": "BackgroundImageFix"
    }
  }), _c('feBlend', {
    attrs: {
      "mode": "normal",
      "in": "SourceGraphic",
      "in2": "BackgroundImageFix",
      "result": "shape"
    }
  }), _c('feGaussianBlur', {
    attrs: {
      "stdDeviation": "164",
      "result": "effect1_foregroundBlur_1103:7627"
    }
  })], 1)])])]), _c('public-header'), _c('section', {
    staticClass: "hero",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "hero__content"
  }, [_vm._m(0), _c('div', {
    staticClass: "hero__btns",
    class: _vm.$mq
  }, [_c('PButton', {
    staticClass: "btn",
    attrs: {
      "tag": "router-link",
      "to": "/register",
      "active-class": ""
    }
  }, [_vm._v(" Регистрация ")]), _c('router-link', {
    staticClass: "hero__btns__learn",
    staticStyle: {
      "visibility": "hidden"
    },
    attrs: {
      "to": "/features"
    }
  }, [_vm._v(" Узнать больше о сервисе ")])], 1), _vm._m(1)]), false ? _c('div', {
    staticClass: "hero__search"
  }, [_c('global-search', {
    attrs: {
      "placeholder": "Поиск: {scope}",
      "hide-button": "",
      "tall": ""
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "hero__screenshots",
    class: _vm.$mq
  }, [_c('img', {
    staticClass: "radial",
    attrs: {
      "src": require(`@/assets/images/hero/radial.png`),
      "srcset": `${require(`@/assets/images/hero/radial@2x.png`)} 2x`
    }
  }), _c('img', {
    staticClass: "main",
    attrs: {
      "src": require(`@/assets/images/hero/main.png`),
      "srcset": `${require(`@/assets/images/hero/main@2x.png`)} 2x`,
      "width": "694",
      "height": "467"
    }
  }), _c('img', {
    staticClass: "chart",
    attrs: {
      "src": require(`@/assets/images/hero/chart.png`),
      "srcset": `${require(`@/assets/images/hero/chart@2x.png`)} 2x`
    }
  })])]), _c('section', {
    staticClass: "tools",
    class: _vm.$mq
  }, [_c('h2', {
    staticClass: "h1 tools__title",
    class: _vm.$mq
  }, [_vm._v(" Полный набор эффективных инструментов "), _c('br'), _vm._v("для начинающих и крупных селлеров ")]), _c('div', {
    staticClass: "tools__content"
  }, [_c('div', {
    staticClass: "tools__row tools__row1",
    class: _vm.$mq
  }, [_c('a', {
    staticClass: "tools__block tools__block-1",
    attrs: {
      "href": "https://help.salesfinder.ru/#rec490132978",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/1.svg'),
      "alt": "Icon"
    }
  }), _c('div', {
    staticClass: "tools__title-row"
  }, [_c('h3', {
    staticClass: "tools__block__title"
  }, [_vm._v(" Внешняя аналитика ")]), _c('svg', {
    staticClass: "tools__block__title-arrow",
    attrs: {
      "width": "27",
      "height": "16",
      "viewBox": "0 0 27 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9L26 9V7L0 7L0 9Z",
      "fill": "#824145"
    }
  })])]), _c('p', {
    staticClass: "tools__text"
  }, [_vm._v(" Анализируйте продажи конкурентов, ищите быстрорастущие ниши и ловите зарождающиеся тренды. Глубокая аналитика товаров, категорий и брендов. ")])]), _c('a', {
    staticClass: "tools__block tools__block-2",
    attrs: {
      "href": "https://help.salesfinder.ru/salesfinder-monitoring-rrc",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/2.svg'),
      "alt": "Icon"
    }
  }), _c('div', {
    staticClass: "tools__title-row"
  }, [_c('h3', {
    staticClass: "tools__block__title"
  }, [_vm._v("Мониторинг РРЦ")]), _c('svg', {
    staticClass: "tools__block__title-arrow",
    attrs: {
      "width": "27",
      "height": "16",
      "viewBox": "0 0 27 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9L26 9V7L0 7L0 9Z",
      "fill": "#824145"
    }
  })])]), _c('p', {
    staticClass: "tools__text"
  }, [_vm._v(" Оперативно отслеживайте изменение цен конкурентов и цены на собственные товары при случайном попадании в маркетинговые акции Wildberries и Ozon ")])]), _c('a', {
    staticClass: "tools__block tools__block-3",
    attrs: {
      "href": "https://help.salesfinder.ru/#rec538055321",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/3.svg'),
      "alt": "Icon"
    }
  }), _c('div', {
    staticClass: "tools__title-row"
  }, [_c('h3', {
    staticClass: "tools__block__title"
  }, [_vm._v("SEO анализ")]), _c('svg', {
    staticClass: "tools__block__title-arrow",
    attrs: {
      "width": "27",
      "height": "16",
      "viewBox": "0 0 27 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9L26 9V7L0 7L0 9Z",
      "fill": "#824145"
    }
  })])]), _c('p', {
    staticClass: "tools__text"
  }, [_vm._v(" Оценивайте сколько продаж у товаров с определенных запросов в поиске маркетплейсов. Мониторьте позиции конкурентов и подбирайте эффективные ключевые слова для продвижения карточек товара в несколько кликов ")])])]), _c('div', {
    staticClass: "tools__row tools__row2",
    class: _vm.$mq
  }, [_c('a', {
    staticClass: "tools__block tools__block-4",
    attrs: {
      "href": "https://help.salesfinder.ru/#rec747387751",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/4.svg'),
      "alt": "Icon"
    }
  }), _c('div', {
    staticClass: "tools__title-row"
  }, [_c('h3', {
    staticClass: "tools__block__title"
  }, [_vm._v("Репрайсер цен")]), _c('svg', {
    staticClass: "tools__block__title-arrow",
    attrs: {
      "width": "27",
      "height": "16",
      "viewBox": "0 0 27 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9L26 9V7L0 7L0 9Z",
      "fill": "#824145"
    }
  })])]), _c('p', {
    staticClass: "tools__text"
  }, [_vm._v(" Отстраивайтесь от цен конкурентов, корректируйте стоимость товаров на основе динамики заказов и компенсируйте СПП. Не попадайте в out-of-stock с помощью опции “Контроль остатков” ")])]), _c('a', {
    staticClass: "tools__block tools__block-5",
    attrs: {
      "href": "https://help.salesfinder.ru/#rec490136094",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/5.svg'),
      "alt": "Icon"
    }
  }), _c('div', {
    staticClass: "tools__title-row"
  }, [_c('h3', {
    staticClass: "tools__block__title"
  }, [_vm._v(" Автоответы на отзывы ")]), _c('svg', {
    staticClass: "tools__block__title-arrow",
    attrs: {
      "width": "27",
      "height": "16",
      "viewBox": "0 0 27 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9L26 9V7L0 7L0 9Z",
      "fill": "#824145"
    }
  })])]), _c('p', {
    staticClass: "tools__text"
  }, [_vm._v(" Сводная статистика по магазинам с обработанными отзывами и отзывами без ответов. Настраивайте автоматические сценарии ответов и рекомендации с сопутствующими товарами ")])]), _c('a', {
    staticClass: "tools__block tools__block-6",
    attrs: {
      "href": "https://help.salesfinder.ru/#rec691810683",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/6.svg'),
      "alt": "Icon"
    }
  }), _c('div', {
    staticClass: "tools__title-row"
  }, [_c('h3', {
    staticClass: "tools__block__title"
  }, [_vm._v(" API кабинет продавца WB и Ozon ")]), _c('svg', {
    staticClass: "tools__block__title-arrow",
    attrs: {
      "width": "27",
      "height": "16",
      "viewBox": "0 0 27 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9L26 9V7L0 7L0 9Z",
      "fill": "#824145"
    }
  })])]), _c('p', {
    staticClass: "tools__text"
  }, [_vm._v(" Анализируйте продажи конкурентов, ищите быстрорастущие ниши и ловите зарождающиеся тренды. Глубокая аналитика товаров, категорий и брендов ")])]), _c('a', {
    staticClass: "tools__block tools__block-7",
    attrs: {
      "href": "https://help.salesfinder.ru/salesfinder-plugin",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "tools__block__img",
    attrs: {
      "src": require('@/assets/images/tools/7.svg'),
      "alt": "Icon"
    }
  }), _c('div', {
    staticClass: "tools__title-row"
  }, [_c('h3', {
    staticClass: "tools__block__title"
  }, [_vm._v(" Расширение для браузера ")]), _c('svg', {
    staticClass: "tools__block__title-arrow",
    attrs: {
      "width": "27",
      "height": "16",
      "viewBox": "0 0 27 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9L26 9V7L0 7L0 9Z",
      "fill": "#824145"
    }
  })])]), _c('p', {
    staticClass: "tools__text"
  }, [_vm._v(" Быстрый анализ показателей товаров прямо на страницах маркетплейсов. Оценивайте рекламную выдачу и ставки конкурентов на Вайлдберриз, смотрите в карточке детальные отчеты SKU ")])])])])]), _c('section', {
    staticClass: "overview",
    class: _vm.$mq
  }, [_c('h2', {
    staticClass: "h1 overview__title",
    class: _vm.$mq
  }, [_vm._v(" Обзор возможностей сервиса SalesFinder ")]), _c('div', {
    staticClass: "overview__content",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "overview__video",
    class: _vm.$mq
  }, [_c('iframe', {
    staticClass: "experts__item__video",
    class: _vm.$mq,
    attrs: {
      "width": "560",
      "height": "290",
      "src": "https://vkvideo.ru/video_ext.php?oid=-218418638&id=456239028&hd=2",
      "allow": "aaccelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "frameborder": "0",
      "allowfullscreen": ""
    }
  })]), _c('div', {
    staticClass: "overview__list",
    class: _vm.$mq
  }, [_c('ul', {
    staticClass: "custom-ul custom-ul--violet"
  }, [_c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Данные по Ozon и Wildberries ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Анализ ниш, поиск новинок и трендов ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Анализ продаж конкурентов ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Внутренняя аналитика магазина по API ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Подбор поисковых запросов ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Мониторинг позиций товаров ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Расширение для браузера Chrome и Yandex ")]), _c('li', {
    staticClass: "custom-ul__li",
    class: _vm.$mq
  }, [_vm._v(" Мониторинг цен и изменений конкурентов ")])])])])]), _c('section', {
    staticClass: "experts",
    class: _vm.$mq
  }, [_c('h2', {
    staticClass: "h1 experts__title",
    class: _vm.$mq
  }, [_vm._v(" Посмотрите, как селлеры используют инструменты SalesFinder в работе ")]), _c('div', {
    staticClass: "experts__content",
    class: _vm.$mq
  }, _vm._l(_vm.videos, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "experts__item"
    }, [_c('h3', {
      staticClass: "experts__item__title",
      class: _vm.$mq
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "experts__item__text",
      class: _vm.$mq
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('iframe', {
      staticClass: "experts__item__video",
      class: _vm.$mq,
      attrs: {
        "width": "560",
        "height": "290",
        "src": item.link,
        "allow": "aaccelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "frameborder": "0",
        "allowfullscreen": ""
      }
    })]);
  }), 0)]), _c('section', {
    staticClass: "features"
  }, [_c('h2', {
    staticClass: "h1 features__title",
    class: _vm.$mq
  }, [_vm._v(" Данные для принятия умных решений ")]), _c('div', {
    staticClass: "feature",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Узнайте, что реально продается на маркетплейсах ")]), _c('div', {
    staticClass: "feature__text"
  }, [_vm._v(" Ищете идеи товаров для продажи на маркетплейсах? Наши данные помогут определить бренды, категории и виды товаров с гарантированно высоким и стабильным спросом. ")])]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "--width": "2688",
      "--height": "2100"
    }
  }, [_c('client-only', [_vm.mounted ? _c('LottieFeature1', {
    on: {
      "mounted": function ($event) {
        return _vm.onLottieMounted();
      }
    }
  }) : _vm._e()], 1)], 1)]), _c('div', {
    staticClass: "feature reverse",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Анализируйте стратегию конкурентов ")]), _c('div', {
    staticClass: "feature__text"
  }, [_vm._v(" Не отставайте от конкурентов, реагируйте вовремя и оставайтесь впереди. Ежедневно вы в курсе последних действий конкурирующих брендов и продавцов: отслеживайте изменение их цен, скидок, остатков, запуск новых товаров и категорий. ")])]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "--width": "2688",
      "--height": "1800"
    }
  }, [_c('client-only', [_vm.lottieCount >= 1 ? _c('LottieFeature2', {
    on: {
      "mounted": function ($event) {
        return _vm.onLottieMounted();
      }
    }
  }) : _vm._e()], 1)], 1)]), _c('div', {
    staticClass: "feature",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Отслеживайте позиции по целевым поисковым запросам ")]), _vm._m(2)]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "--width": "491",
      "--height": "326"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": require(`@/assets/images/features/feature4.png`)
    }
  })])]), _c('div', {
    staticClass: "feature reverse",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Оптимизируйте продажи и поставки вашего магазина ")]), _c('div', {
    staticClass: "feature__text"
  }, [_vm._v(" Подключите свой магазин на Wildberries и OZON к SalesFinder - и получите детальную аналитику ваших показателей, а также и удобные инструменты для увеличения вашей прибыли. ")])]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "--width": "491",
      "--height": "292"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": require(`@/assets/images/features/feature3.png`)
    }
  })])]), _c('div', {
    staticClass: "feature",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Найдите самые"), _c('br'), _vm._v("прибыльные ниши ")]), _c('div', {
    staticClass: "feature__text"
  }, [_vm._v(" С помощью продвинутых рейтингов вы можете за секунды определить категории с необходимыми вам параметрами: количество товаров и брендов, средний чек, % товаров с продажами, общий объем выручки в месяц и многое другое. ")])]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "--width": "1444",
      "--height": "1003"
    }
  }, [_c('client-only', [_vm.lottieCount >= 2 ? _c('LottieFeature3', {
    on: {
      "mounted": function ($event) {
        return _vm.onLottieMounted();
      }
    }
  }) : _vm._e()], 1)], 1)]), _c('div', {
    staticClass: "feature reverse",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Отслеживайте новые тренды ")]), _c('div', {
    staticClass: "feature__text"
  }, [_vm._v(" Ежедневно мы анализируем продажи десятков миллионов товаров во всех категориях маркетплейсов. Вы не упустите момент, когда стоит обратить внимание на быстрорастущие новинки. ")])]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "--width": "2688",
      "--height": "1648"
    }
  }, [_c('client-only', [_vm.lottieCount >= 3 ? _c('LottieFeature4', {
    on: {
      "mounted": function ($event) {
        return _vm.onLottieMounted();
      }
    }
  }) : _vm._e()], 1)], 1)]), _c('div', {
    staticClass: "feature",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Ответ на главный вопрос:"), _c('br'), _vm._v(" Что влияет на продажи? ")]), _c('div', {
    staticClass: "feature__text"
  }, [_vm._v(" Анализируйте динамику метрик интересующих товаров, чтобы узнать, какие факторы реально влияют на продажи: изменение цены, уровень скидки, кол-во отзывов или увеличение видимости в категориях. ")])]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "--width": "491",
      "--height": "237"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": require(`@/assets/images/features/feature5.png`),
      "srcset": `${require(`@/assets/images/features/feature5@2x.png`)} 2x`
    }
  })])]), _c('div', {
    staticClass: "feature reverse",
    class: _vm.$mq
  }, [_c('div', {
    staticClass: "feature__content",
    class: _vm.$mq
  }, [_c('h3', {
    staticClass: "feature__title",
    class: _vm.$mq
  }, [_vm._v(" Установите расширение SalesFinder для браузера Chrome и Яндекс ")]), _c('div', {
    staticClass: "feature__text"
  }, [_vm._v(" Анализируйте товары и скачивайте отчеты прямо на страницах маркетплейсов OZON и Wildberries: динамика показателей, продажи и выручка, поисковые запросы и позиции, продажи по складам и размерам. ")]), _c('div', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('Plugin-btn', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" Установить расширение ")])], 1)]), _c('div', {
    staticClass: "feature__image",
    class: _vm.$mq,
    staticStyle: {
      "--width": "491",
      "--height": "300"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": require(`@/assets/images/features/feature6.png`)
    }
  })])])]), _c('section', {
    staticClass: "reviews",
    class: _vm.$mq
  }, [_c('reviews-gallery')], 1), _c('public-footer')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('h1', {
    staticClass: "h1 hero__title"
  }, [_vm._v(" SalesFinder - аналитика маркетплейсов"), _c('br'), _vm._v(" для увеличения "), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("ваших продаж")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "hero__description"
  }, [_vm._v(" Создайте аккаунт и получите "), _c('span', {
    staticClass: "highlight"
  }, [_vm._v("бесплатный демо-доступ")]), _vm._v(" "), _c('br'), _vm._v(" ко всем инструментам ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "feature__text"
  }, [_vm._v(" Анализируйте самые популярные запросы товаров конкурентов или целых категорий для оптимизации поисковой видимости своих карточек. "), _c('br'), _vm._v(" Запустите мониторинг позиций для ежедневного контроля позиций ваших товаров на Wildberries и OZON. ")]);
}];
export { render, staticRenderFns };