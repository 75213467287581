import PublicHeader from "../Header";
import PublicFooter from "../Footer";
import GlobalSearch from "@/components/GlobalSearch/GlobalSearch";
import ReviewsGallery from "./ReviewsGallery";
import PluginBtn from "@/components/PluginBtn.vue";
export default {
  meta: {
    /*@V:
            titleTemplate: () => 'SalesFinder - Сервис аналитики маркетплейсов WB и OZON',
            meta: [
                { name: 'description', content: 'SalesFinder - Поиск прибыльных ниш, анализ цен и продаж конкурентов, трекинг позиций, данные по брендам и продавцам. Удобная аналитика маркетплейсов для увеличения ваших продаж!' },
            ]
            */
    //// &: 2022-06-24title: 'SalesFinder - Сервис аналитики продаж на маркетплейсах WB и OZON',
    titleTemplate: () => "SalesFinder - Сервис аналитики продаж на маркетплейсах WB и OZON",
    meta: [{
      name: "description",
      content: "SalesFinder - Поиск прибыльных ниш, анализ цен и продаж конкурентов на Wildberries и OZON, трекинг позиций, данные по брендам и продавцам. Удобная аналитика маркетплейсов для увеличения ваших продаж!"
    }, {
      name: "viewport",
      content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
    }]
  },
  data() {
    return {
      mounted: false,
      lottieCount: 0,
      videos: [{
        link: "https://vkvideo.ru/video_ext.php?oid=-218418638&id=456239030&hd=2",
        title: "Как работает финансовый анализ в SalesFinder?",
        text: "Финансовый анализ SalesFinder помогает оценить финансовые показатели магазина и прибыльность каждого товара, включая расходы на логистику, комиссии, процент возврата, выручку и прибыль."
      }, {
        link: "https://vkvideo.ru/video_ext.php?oid=-218418638&id=456239024&hd=2",
        title: "Как избежать out of stock при помощи SalesFinder?",
        text: "Как с помощью инструмента «Репрайсер» автоматически отслеживать остатки товаров и поднимать цену при снижении количества ниже заданного уровня, чтобы избежать попадания товара в out-of-stock."
      }, {
        link: "https://vkvideo.ru/video_ext.php?oid=-218418638&id=456239031&hd=2",
        title: "Анализ товаров через расширение для браузеров",
        text: "Плагин для браузера позволяет анализировать товары и скачивать отчеты прямо на страницах OZON и Wildberries, получая данные о динамике показателей, продажах, выручке, поисковых запросах, позициях, продажах по складам и размерам."
      }, {
        link: "https://vkvideo.ru/video_ext.php?oid=-218418638&id=456239023&hd=2",
        title: "Коротко о том, как работает репрайсер",
        text: "Репрайсер для OZON и Wildberries автоматически управляет ценами, позволяя зарабатывать больше за счет динамического ценообразования в режиме реального времени."
      }, {
        link: "https://vkvideo.ru/video_ext.php?oid=-218418638&id=456239036&hd=2",
        title: "Расчет оптимальной поставки товаров на маркетплейсы OZON и Wildberries.",
        text: "Инструмент расчета поставки поможет определить оптимальное количество товаров для отгрузки на маркетплейсы, чтобы избежать дефицита и избытка товара на складе."
      }, {
        link: "https://vkvideo.ru/video_ext.php?oid=-218418638&id=456239039&hd=2",
        title: "Как SalesFinder помогает продавать больше",
        text: "SalesFinder, предоставляет углубленные данные о рынке, товарах и конкурентах, помогая продавцам принимать обоснованные решения для увеличения продаж и эффективности работы."
      }]
    };
  },
  async mounted() {
    await this.$nextTick();
    this.mounted = true;
  },
  methods: {
    async onLottieMounted() {
      await this.$nextTick();
      this.lottieCount++;
    }
  },
  components: {
    PublicHeader,
    GlobalSearch,
    PluginBtn,
    LottieFeature1: () => import("./Lottie/Feature1"),
    LottieFeature2: () => import("./Lottie/Feature2"),
    LottieFeature3: () => import("./Lottie/Feature3"),
    LottieFeature4: () => import("./Lottie/Feature4"),
    ReviewsGallery,
    PublicFooter
  }
};